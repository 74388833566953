// @ts-ignore
// eslint-disable-next-line camelcase
__webpack_public_path__ = window['~hpe~'] && window['~hpe~'].root || '/js/';

// Initial ESL config
import 'core/config/esl.config';

import {onDocumentReady} from '@exadel/esl/modules/esl-utils/dom/ready';

import {ESLImage} from '@exadel/esl/modules/esl-image/core';
import {ESLImageContainerMixin} from '@exadel/esl/modules/esl-image-utils/core';
import {ESLMedia} from '@exadel/esl/modules/esl-media/core';
import {HPEBulletColorMixin} from 'styleguide/bullet-color-mixin';
import {HPEMediaRestriction} from 'core/media.resctriction';

// Critical components registration
ESLImage.register('smart-image');
ESLImageContainerMixin.register();
ESLMedia.register('smart-media');
HPEBulletColorMixin.register();

HPEMediaRestriction.init();

// Fallback Media Providers
import 'core/providers/https-provider';
import '@exadel/esl/modules/esl-media/providers/iframe-provider';

// Raw-video provider: (for attract loop)
import '@exadel/esl/modules/esl-media/providers/html5/video-provider';

// ESL Media Providers
onDocumentReady(() => import(/* webpackChunkName: "core/media.providers" */'core/media.providers'));
